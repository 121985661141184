import Contact from './services/contact'

try {
  $(document).ready(function () {
    let $form = $('#contact-form')
    if ($form && $form.length > 0) {
      new Contact($form)
    }
  })
} catch (err) {
  // eslint-disable-next-line no-console
  console.error(err)
}
