import '../scss/app.scss'
import 'bootstrap'

try {
    let $ = require('jquery')
    $(window).on('load', function () {
        setTimeout(function () {
            $('.page-loader').addClass('loaded')
            $(window).trigger('resize')
        }, 200)
    })

    $(document).ready(function () {
        require('./nav')
        require('./contact')
    })
} catch (error) {
    //remove preloader
    $('.page-loader').addClass('loaded')
    $(window).trigger('resize')
    // eslint-disable-next-line no-console
    console.error(error)
}
