import RequestClient from './requestClient.js'

class Contact {
  /**
   *
   * @param $form
   */
  constructor($form) {
    Contact.handleResponse($form)
    Contact.setEventListener($form)
    Contact.submitFormListener($form)
    Contact.set('isValid', false)
  }

  static get(property) {
    return this[property]
  }

  static set(property, value) {
    return (this[property] = value)
  }

  /**
   *
   * @param $form
   */
  static setEventListener($form) {
    //remove white space
    $form.find('input[type="text"]').blur(function () {
      this.value = $.trim(this.value)
    })
  }

  /**
   *
   * @param $form
   */
  static submitFormListener($form) {
    $form.submit(function (event) {
      event.preventDefault()
      if (Contact.get('isValid') === false) {
        //deactivate submit button
        Contact.toggleSubmitButton(true, $form)
        //get input data
        let postData = {}
        $.each(
          $form.find(
            'input:not(:checkbox):not(:button),select,textarea, input:checked'
          ),
          function () {
            postData[$(this).prop('name')] = $(this).val()
          }
        )

        new RequestClient(
          'POST',
          $form.data('validate'),
          postData,
          'RequestFinishedEvent'
        )
      }
    })
  }

  /**
   *
   * @param $disable
   * @param $form
   */
  static toggleSubmitButton($disable, $form) {
    $form
      .find(':submit')
      .prop('disabled', $disable)
      .toggleClass('disabled', $disable)
      .find('i')
      .toggleClass('d-none', !$disable)
  }

  /**
   *
   * @param $form
   */
  static handleResponse($form) {
    let element
    $(document).on('RequestFinishedEvent', function (event, status, data) {
      if (status !== 200) {
        //activate submit button
        Contact.toggleSubmitButton(false, $form)
        //reset form fields
        $form.find('.invalid').removeClass('invalid')
        $.each(data, function (index, error) {
          //mark inputs
          element = $form.find('#' + error.key)
          element.addClass('invalid')
          if (element.prop('type') !== 'checkbox') {
            element.next('label').text(error.message)
            element.keyup(function () {
              if (this.validity.valid) {
                $(this).removeClass('invalid')
              } else {
                $(this).addClass('invalid')
              }
            })
          }
        })
        //set focus on first error inout
        $form.find('.invalid:first').focus()
      } else {
        Contact.set('isValid', true)
        Contact.toggleSubmitButton(false, $form)

        Contact.set('isValid', false)

        $form.trigger('reset')

        $form.hide()
        $('.alert').toggle('.d-none')

        setTimeout(function () {
          $form.show()
          $('.alert').toggle('.d-none')
        }, 8000)
      }
    })
  }
}

export default Contact
