class RequestClient {
  /**
   *
   * @param method
   * @param uri
   * @param data
   * @param event
   */
  constructor(method, uri, data, event) {
    try {
      let eventName = event || 'RequestFinishedEvent'
      $.ajax({
        dataType: 'json',
        cache: false,
        timeout: 100000,
        method: method,
        url: uri,
        data: data,
        headers: {},
      }).always(function ({ responseJSON }, jxhr, { status }) {
        RequestClient.handleRequest(status, responseJSON, eventName)
      })
    } catch (error) {
      // eslint-disable-next-line no-console
      window.console && console.warn(error)
    }
  }

  /**
   *
   * @param status
   * @param json
   * @param eventName
   */
  static handleRequest(status, json, eventName) {
    //send event
    let event = $.Event(eventName)
    $(document).triggerHandler(event, [status, json])
  }
}

export default RequestClient
