module.exports = (function () {
  let nav = $('.rd-navbar')
  let xmode = window.xMode
  /**
   * RD Navbar
   * @description Enables RD Navbar plugin
   */
  nav.RDNavbar({
    stickUpClone:
      nav.attr('data-stick-up-clone') && !xmode
        ? nav.attr('data-stick-up-clone') === 'true'
        : false,
    responsive: {
      0: {
        stickUp: !xmode ? nav.attr('data-stick-up') === 'true' : false,
      },
      768: {
        stickUp: !xmode ? nav.attr('data-sm-stick-up') === 'true' : false,
      },
      992: {
        stickUp: !xmode ? nav.attr('data-md-stick-up') === 'true' : false,
      },
      1200: {
        stickUp: !xmode ? nav.attr('data-lg-stick-up') === 'true' : false,
      },
    },
  })
  if (nav.attr('data-body-class')) {
    document.body.className += ' ' + nav.attr('data-body-class')
  }
})()
